<template>
  <v-container class="fill-height">
    <v-responsive class="align-center text-center fill-height">
      <v-btn @click="anaf" :loading="state.loading" :disabled="state.loading">ACEES ANAF</v-btn>
    </v-responsive>
  </v-container>
</template>

<script lang="ts">
import {defineComponent, reactive} from 'vue'

export default defineComponent({
  setup () {
    const state = reactive({
      loading: false
    })
    const anaf = () => {
      state.loading = true
      const baseUrl = `${process.env.VUE_APP_ANAF_API_OAUTH_URL}/authorize`
      const params = {
        client_id: process.env.VUE_APP_ANAF_API_CLIENT_ID,
        redirect_uri: process.env.VUE_APP_ANAF_API_CALLBACK_URL,
        response_type: 'code',
        token_content_type: 'jwt'
      }
      console.log({ baseUrl, params })
      const url = new URL(baseUrl)
      for (const [q, v] of Object.entries(params)) {
        // @ts-ignore
        url.searchParams.set(q, v)
      }
      window.location.href = url.toString();
    }
    return {
      state,
      anaf
    }
  }
})
</script>
